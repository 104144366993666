<template>
  <div>
    <v-container fluid class="pa-0" :style="{'margin-top': windowWidth > 959 ? '-90px' : '-65px'}">
      <v-img :src="require('@/assets/images/bg1about.jpg')"></v-img>
    </v-container>
    <v-container class="mt-sm-n16 mt-n8 position-relative" style="z-index: 1">
      <v-row>
        <v-col cols="12" class="pb-4">
          <h2 class="about-first__title text-center">Что такое Атоми?</h2>
        </v-col>
        <v-col cols="12" class="about-first__text pt-3">
          <v-img :src="require('@/assets/images/image1about.jpg')" :width="windowWidth > 599 ? '45%' : '100%'"
                 height="auto"
                 class="float-sm-left float-none mr-4 mb-4"></v-img>
          <p>
            Atomy – Южно-Корейская компания, прославившаяся натуральными и безопасными продуктами премиального качества
            по сравнительно низким ценам. Компания Atomy основана в 2009 году и пользуется популярностью
            в более чем в 50 крупнейших странах мира. А с 3 мая 2019 года официально открыта в России.
            Продажа продукции осуществляется через клубный интернет-магазин компании.
          </p>
          <p class="text-center">Основатель компании – Пак Хан Гиль.</p>
          <div class="float-sm-right float-none position-relative pl-sm-4 mb-lg-4 pb-sm-0 pb-3"
               :style="{'width': windowWidth > 599 ? 'calc(55% - 16px)' : '100%'}">
            <v-img :src="require('@/assets/images/image2about.jpg')" width="100%" height="auto"></v-img>
          </div>
          <p>
            Atomy показывает феноменальный рост по финансовым сравнительным показателям, так что на 8 год существования
            достигла мирового товарооборота в 1 млрд $ и к 2022 году увеличила этот показатель в 2 раза, достигнув
            товарооборота в 2 млрд $.
          </p>
          <p>
            Бесплатная регистрация, отсутствие стартового набора, членских взносов, абонентской платы и обязательных
            закупок делают компанию Atomy лидером в условиях для клиентов, новых партнеров и постоянных участников.
            Благодаря созданному стабильному мировому товарообороту корейская компания щедро платит всем своим партнерам.
          </p>
          <p>
            Большая часть продукции
            Atomy преимущественно изготавливается на фармацевтическом заводе Kolmar в Южной Корее, который выпускает
            продукцию для многих известных брендов и крупных компаний, таких как: Shanel, Amway, Mary Kay, L'oreal,
            Sasha, Lancome, Shiseido и т.д. Остальная продукция компании изготавливается ведущими производителями
            в Южной Корее и других странах мира, соблюдающими высокие стандарты качества продукции.
          </p>
          <p>
            Продукция компании имеет следующие сертификаты качества: Международный стандарт качества GMP и HACCP,
            Стандарт FDA, Международный экологический сертификат LOHAS, Сертификат Государственного органа по контролю
            за продукцией Южная Корея (на всей продукции), а также сертификаты качества, установленные законодательством
            каждой отдельной страны, где открыты официальные филиалы Атоми.
          </p>
        </v-col>
        <v-col cols="12" class="pb-sm-4 pt-sm-4">
          <h2 class="about-second__title text-center">Корпорация Kolmar</h2>
        </v-col>
        <v-col cols="12" class="about-second__text">
          <v-img :src="require('@/assets/images/image3about.jpg')"
                 class="about-second__img float-sm-left float-none mr-sm-4 mb-sm-0 mb-3"></v-img>
          <p>
            История корпорации Kolmar началась в начале 1921 года.
          </p>
          <p>
            Именно в 1921 году основатель компании Лессинг Л. Коле (Lessing L. Kole) арендовал небольшой магазин и
            продавал производимые им порошки и румяна. Вскоре после этого Коле объединился с доктором Фредериком
            Марсеком (Dr. Frederick Marsek), который когда-то был главным химиком в компании Palmolive. Вдвоём они
            создали имя Колмар (Kolmar), соединив буквы из своих фамилий Кол + Мар (Kol + Mar).
          </p>
          <p>
            За десятилетия своего существования, Kolmar зарекомендовал себя, как ведущий мировой производитель
            косметики. Kolmar имеет свои филиалы в США, Корее, Канаде, Японии, Австралии и других странах. Корпорация
            имеет свои научно-исследовательские институты, производственные и маркетинговые сети которые работают по
            всему миру.
          </p>
          <p>
            Как профессиональная компания по изготовлению косметики, компания Korea Kolmar имеет лучшие, и независимые
            ни от кого, возможности в области исследований и разработок. С 1994 года компания занимает третье место в
            мире по CGMP (Мировые стандарты производства превосходной косметики и управления качеством).
          </p>
          <p>
            За 100 лет изучений и исследований компания Kolmar имеет на своём счету более 20000 методик, патентов и
            рецептов. Именно это позволяет компании Kolmar быстро адаптироваться к изменениям и вести за собой весь
            фармацевтический мир.
            В настоящее время корейская компания Kolmar занимает первое место в области функциональной косметики в Корее
            и является ПЕРВЫМ В МИРЕ разработчиком, исследующим безопасные технологии.
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: ['windowWidth'],
}
</script>

<style lang="sass" scoped>
.about
  &-first
    &__title
      font-size: 32px
      //font-family: "Atomy Bold"
      font-family: 'Roboto', sans-serif
      font-weight: bold

    &__text
      font-size: 20px
      text-align: justify
      //font-family: "Atomy Light"
      font-family: 'Roboto', sans-serif
      font-weight: normal

  &-second
    &__title
      font-size: 32px
      //font-family: "Atomy Bold"
      font-family: 'Roboto', sans-serif
      font-weight: bold

    &__img
      width: 53%
      height: auto

    &__text
      font-size: 20px
      text-align: justify
      //font-family: "Atomy Light"
      font-family: 'Roboto', sans-serif
      font-weight: normal

@media (max-width: 1903.98px)
  .about
    &-second
      &__img
        width: calc(50% + 44px)
        height: auto

@media (max-width: 1263.99px)
  .about
    &-first
      &__title
        font-size: 26px

      &__text
        font-size: 16px

    &-second
      &__title
        font-size: 26px

      &__img
        width: calc(50% + 50px)
        height: auto

      &__text
        font-size: 16px

@media (max-width: 599.98px)
  .about
    &-second
      &__img
        width: 100%
</style>
